import { Header } from "../../components/Header"
import { Link } from "react-router-dom"
import ajax from "../../assets/ajax.png"
import bloquage from "../../assets/Metiers/bloquage.png"
import camera from "../../assets/Metiers/camera.png"
import plus from "../../assets/plus.png"
import { Helmet } from 'react-helmet';
import bell from "../../assets/bell.png"
import digicode from "../../assets/digicode.png"
import heure from "../../assets/horloge.png"
import audit from "../../assets/Metiers/audit.png"
import communication from "../../assets/Metiers/communication.png"
import formation from "../../assets/Metiers/formation.png"
import reglage from "../../assets/Metiers/reglage.png"


export const Securite = () => {
    const scrollToTop = () => {
        window.scrollTo(0, 0);
      };
      const goToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        })
    }
    return(
        <div className="w-full flex flex-col justify-center items-center lg:text-2xl text-lg">
            <Helmet>
                <title>Gestion de la sécurité | D.H. Quartz</title>
            </Helmet>
            <Header title="Gestion de la sécurité"/>
            <div className="w-[80%] flex lg:flex-row flex-col items-center justify-between lg:space-y-0 space-y-6">
                <div className="lg:w-[45%] w-full flex justify-center items-center">
                    <img src={ajax} alt="Ajax" className="lg:w-[450px] w-[250px]" />
                </div>
                <div className="lg:w-[50%] w-full flex flex-col justify-between items-start space-y-4">
                    <h2 className="lg:text-4xl text-2xl font-semibold">Pour les entreprises et les particuliers</h2>
                    <p>Nous proposons des systèmes d'alarme sur mesure pour les particuliers et les entreprises. Partenaire de Securitas depuis plus de 20 ans, DH Quartz vous offre des solutions allant de l'alarme anti-intrusion à la surveillance continue par caméras. Nous assurons également l'entretien et le dépannage de vos équipements dans les Ardennes.</p>
                </div>
            </div>

            <div className="w-[80%] flex lg:flex-row flex-col items-start justify-between mt-10 lg:space-y-0 space-y-8">
                <div className="lg:w-[45%] w-full flex flex-col space-y-6">
                    <p className="font-semibold w-full text-center rounded-full bg-[#B6872D] text-white py-3">Nos services d'installation :</p>
                    <ul className="list-disc list-inside space-y-4">
                        <li><span className="italic font-semibold">Audit personnalisé</span> : Nous analysons vos besoins pour vous recommander la meilleure solution en fonction de votre budget.</li>
                        <li><span className="italic font-semibold">Installation professionnelle</span> : Mise en place de systèmes d'alarme, de caméras de surveillance et de contrôle d'accès en garantissant leur conformité et leur performance optimale.</li>
                        <li><span className="italic font-semibold">Formation utilisateur</span> : Nous assurons que vous êtes parfaitement formé à l'utilisation de votre nouveau système.</li>
                    </ul>
                </div>
                <div className="lg:w-[45%] w-full flex flex-col space-y-6">
                    <p className="font-semibold w-full text-center rounded-full bg-[#B6872D] text-white py-3">Nos services de dépannage :</p>
                    <ul className="list-disc list-inside space-y-4">
                        <li><span className="italic font-semibold">Dépannage multi-marque rapide et efficace</span> : Intervention sur des systèmes de marques comme Honeywell, AJAX, PARADOX, VISONIC, ALTEC, HIKVISION, DAHUA, VANDERBILT, RISCO, ARITECH...et bien d'autres à condition de pouvoir fournir les codes installateurs au technicien pour les équipements non installés par DH Quartz.</li>
                        <li><span className="italic font-semibold">Intervention rapide</span> : Disponibilité assurée pour résoudre vos problèmes de sécurité.</li>
                        <li><span className="italic font-semibold">Maintenance continue</span> : Contrats de maintenance pour assurer la longévité et l'efficacité de vos systèmes de sécurité.</li>
                    </ul>
                </div>
            </div>
            <div className="w-full bg-black text-white flex flex-col justify-center items-center lg:pt-28 pt-16 lg:pb-20 pb-12 lg:mb-16 mb-10 lg:mt-24 mt-16 relative" id="plus">
                <div className="w-[80%] flex lg:flex-row flex-col items-center justify-between">
                    <div className="flex flex-col justify-between items-start lg:w-[50%] w-full space-y-4">
                        <h2 className="lg:text-4xl text-2xl font-semibold">Pour les Établissements Recevant du Public</h2>
                        <p>Les établissements recevant du public ont des besoins spécifiques en matière de sécurité. DH Quartz vous offre des solutions adaptées pour garantir la sécurité de vos visiteurs et de vos biens. Nos techniciens diagnostiquent et installent des systèmes incendie conformes aux normes pour les ERP des Ardennes. En partenariat avec AVISS, nous assurons la maintenance de réseaux d'alarme incendie pour une sécurité maximale.</p>
                        <p className="font-semibold">Plan Particulier de Mise en Sûreté (PPMS) pour Établissements Scolaires</p>
                        <p>La sécurité des écoles, collèges, lycées et autres établissements scolaires ardennais est une priorité. DH Quartz vous propose des solutions complètes pour la mise en place du Plan Particulier de Mise en Sûreté (PPMS), incluant :</p>
                    </div>
                    <div className="lg:w-[45%] w-full flex justify-center items-center lg:mt-0 mt-6">
                        <img src={bloquage} alt="Bloquage" className="w-[90%]" />
                    </div>
                </div>
                <div className="w-[80%] grid grid-cols-4 gap-8 mt-10">
                    <div className="flex flex-col justify-start items-center">
                        <img src={audit} alt="Audit" className="w-[80px] mb-4" />
                        <p className="font-semibold text-center">Audit de sécurité :</p>
                        <p className="text-center">Évaluation des risques spécifiques à chaque établissements.</p>
                    </div>
                    <div className="flex flex-col justify-start items-center">
                        <img src={communication} alt="Communication" className="w-[80px] mb-4" />
                        <p className="font-semibold text-center">Installation de systèmes d'alarme et de communication :</p>
                        <p className="text-center">Pour alerter rapidement en cas d'urgences.</p>
                    </div>
                    <div className="flex flex-col justify-start items-center">
                        <img src={formation} alt="Formation" className="w-[80px] mb-4" />
                        <p className="font-semibold text-center">Formation et exercises :</p>
                        <p className="text-center">Pour préparer le personnel et les élèves aux procédures d'urgence.</p>
                    </div>
                    <div className="flex flex-col justify-start items-center">
                        <img src={reglage} alt="Reglage" className="w-[80px] mb-4" />
                        <p className="font-semibold text-center">Maintenance et suivi :</p>
                        <p className="text-center">Pour assurer que tous les systèmes restent opérationnels et efficace.</p>
                    </div>
                </div>
                <a href="#plus" className="bg-[#F5F5F5] rounded-full lg:w-[150px] lg:h-[150px] w-[70px] h-[70px] absolute flex justify-center items-center lg:-top-[75px] -top-[35px]">
                    <img src={plus} alt="Plus" className="w-[40%]" />
                </a>
            </div>
            <div className="w-[80%] flex lg:flex-row flex-col-reverse items-center justify-between">
                <div className="lg:w-[45%] w-full lg:mt-0 mt-6 flex justify-center items-center">
                    <img src={camera} alt="Caméra" className="w-[90%]" />
                </div>
                <div className="flex flex-col justify-between items-start lg:w-[50%] w-full space-y-4">
                    <h2 className="lg:text-4xl text-2xl font-semibold">Le Saviez-Vous ?</h2>
                    <p className="font-semibold">Fin des Lignes Analogiques et de la 2G : Préparez-Vous avec DH Quartz</p>
                    <p>Avec la fin des lignes analogiques et de la 2G prévue pour fin 2025 par l’opérateur Orange (les autres opérateurs suivront), vos systèmes de sécurité actuels pourraient devenir obsolètes. Faites appel à DH Quartz pour mettre à jour ou remplacer vos équipements de sécurité. Nous vous proposons des solutions modernes et pérennes, adaptées à vos besoins spécifiques et à votre budget.</p>
                    <p>Pourquoi nous choisir pour la mise à jour de votre ancien matériel ?</p>
                    <ul className="list-disc list-inside">
                        <li>Expertise technique pour des mises à jour fiables.</li>
                        <li>Solutions compatibles avec les nouvelles technologies (4G, 5G, IP).</li>
                        <li>Service de proximité dans les Ardennes pour une intervention rapide.</li>
                    </ul>
                </div>
            </div>
            <div className="w-full bg-[#B6872D] text-white flex justify-center items-center lg:pt-28 pt-12 lg:pb-20 pb-10 mt-16">
                <div class="lg:w-[80%] w-[85%] grid lg:grid-cols-3 grid-cols-1 gap-x-16 gap-y-12">
                    <div className="flex flex-col items-center justify-between space-y-2">
                        <img src={digicode} alt="Digicode" className="max-w-[300px] max-h-[200px]" />
                        <div className="w-full flex flex-col space-y-4">
                            <h4 className="font-semibold lg:text-3xl text-2xl">Gestion de l'accès</h4>
                            <p className="lg:line-clamp-2 line-clamp-3">Un contrôle d'accès est idéal pour assurer une véritable sécurité de votre bâtiment. Vous souhaitez sécuriser votre domicile, entreprise ou commerce avec un portail automatique ; pour votre tranquillité d'esprit vous voulez installer un portier électronique à badge voire biométrique à l'entrée de votre bâtiment ; nos artisans saurons vous donner des conseils sur mesure et réaliser l'ensemble des travaux nécessaires. Après l'installation du matériel nous assurons la maintenance de vos barrières, portails et serrures électriques.</p>
                            <Link to="/gestion-des-acces" href="/gestion-des-acces" className=" hover:underline" onClick={goToTop}>En savoir plus</Link>
                        </div>
                    </div>
                    <div className="flex flex-col items-center justify-between space-y-2">
                        <img src={heure} alt="Ajax" className="max-w-[300px] max-h-[200px]" />
                        <div className="w-full flex flex-col space-y-4">
                            <h4 className="font-semibold lg:text-3xl text-2xl">Gestion du temps</h4>
                            <p className="lg:line-clamp-2 line-clamp-3">Quand on a une entreprise employant un certain nombre d'employés, cela peut être difficile de suivre la ponctualité de chacun. Grâce à notre large de gamme de système de contrôle du temps nous pouvons offrir des solutions qui vous seront adaptées. Pour être tous à la même heure, nous nous occupons de l'installation d'affichage d'heure simple ou en réseau, filaire ou sans fil DHF pour votre entreprise, terrain de sport ou affichage public.Nous sommes aussi spécialistes dans la pose d'alarme incendie notamment dans les établissements recevant du public ( écoles, collèges, lycées, ect.)</p>
                            <Link to="/gestion-des-temps-alarmes-incendies-et-ppms#top" href="/gestion-des-temps-alarmes-incendies-et-ppms#top" className=" hover:underline" onClick={goToTop}>En savoir plus</Link>
                        </div>
                    </div>
                    <div className="flex flex-col items-center justify-between space-y-2">
                        <img src={bell} alt="Bell" className="max-w-[300px] max-h-[200px]" />
                        <div className="w-full flex flex-col space-y-4">
                            <h4 className="font-semibold lg:text-3xl text-2xl">Gestion du patrimoine</h4>
                            <p className="lg:line-clamp-2 line-clamp-3">C'est un métier rare et souvent peu connu. Campaniste c'est celui qui crée, répare et entretien les milliers de cloches et d'horloges qui ornent et font vivre nos églises et nos édifices aussi bien dans les grandes villes que dans les villages. Nos techniciens sont à la fois horlogers, électriciens, mais surtout électromécaniciens et électroniciens.</p>
                            <Link to="/gestion-du-patrimoine" href="/gestion-du-patrimoine" className=" hover:underline" onClick={goToTop}>En savoir plus</Link>
                        </div>
                    </div>
                </div>
            </div>
            <div className="w-full bg-black text-white flex justify-center items-center lg:pt-28 pt-12 lg:pb-20 pb-10 ">
                <div className="w-[60%] flex flex-col items-center justify-center text-center space-y-10">
                    <h3 className="lg:text-3xl text-2xl font-semibold">Une question ? Un projet ? </h3>
                    <div>
                        <Link to="/contact" href="/contact" onClick={scrollToTop} className="text-white bg-[#B6872D] px-5 py-2 rounded-2xl">Contactez-nous</Link>
                    </div>
                </div>
            </div>
        </div>
    )
}